import ErrorLogger from '@/ErrorLogger';
import {AnimateOutDurationMs} from '@/components/core/Animation';
import type {ModalProps} from '@/components/modal/Modal';
import Modal from '@/components/modal/Modal';
import type {ModalSpec} from '@/components/modal/ModalSpec';
import modalSpecsProxy, {incrModalId} from '@/components/modal/modalSpecsProxy';
import assert from '@/utils/assert';
import {useValtioSnapshot} from '@/utils/valtio';
import {useCallback, type DependencyList, type MouseEventHandler, type ReactNode} from 'react';
import {observer} from 'mobx-react-lite';

export type ModalSpecProps = Pick<
  ModalProps,
  | 'styleType'
  | 'cancelButtonText'
  | 'hideButtons'
  | 'widthClassName'
  | 'size'
  | 'extraData'
  | 'formSpecDefaultValues'
> &
  Partial<Pick<ModalProps, 'onCancelClick'>> & {
    formSpec?: Partial<ModalProps['formSpec']>;
  } & XOR<Pick<ModalProps, 'content'>, Pick<ModalProps, 'BodyComponent' | 'bodyComponentProps'>> &
  XOR<Pick<ModalProps, 'title'>, Pick<ModalProps, 'TitleComponent' | 'titleComponentProps'>>;

function updateModalSpec({id, props}: {id: ModalSpec['id']; props: Partial<ModalSpec['props']>}) {
  const modalSpec = modalSpecsProxy.value.find((modalSpec) => {
    return modalSpec.id === id;
  });
  assert(modalSpec, `modalSpec is required`);
  // TODO write a deep merge function that is mutable?
  Object.assign(modalSpec, {
    props: Object.assign(modalSpec.props, props),
  });
}

type ModalResult = {
  confirmed: true;
  result: any;
  error?: never;
} & {
  confirmed: false;
  result?: never;
  error?: never;
} & {
  confirmed: true;
  error: Error;
  result?: never;
};

export function openModal(props: ModalSpecProps): Promise<ModalResult> {
  const id = incrModalId();
  let parentResolve;
  let parentReject;
  const promise = new Promise<ModalResult>((resolve, reject) => {
    parentResolve = resolve;
    parentReject = reject;
  });
  const modalSpec: ModalSpec = {
    id,
    props: {
      ...props,
      className: 'absolute',
      animationPhase: 'in',
      formSpec: {
        ...props.formSpec,
        inputSpecs: props.formSpec?.inputSpecs || [],
        async onSubmit(e, ...options) {
          if (!props.formSpec?.onSubmit) {
            closeModal(id);
            parentResolve({confirmed: true});
            return;
          }
          updateModalSpec({
            id,
            props: {
              loading: true,
            },
          });
          try {
            const result = await Promise.resolve(props.formSpec.onSubmit(e, ...options));
            closeModal(id);
            parentResolve({confirmed: true, result});
            // so that you don't see weird jankiness as the form is fading out
            return {ignoreFormReset: true};
          } catch (e) {
            // don't invoke parentReject or this can get into a weird state where downstream `await`s are no longer listening
            ErrorLogger.Log(e as Error);
            throw e;
          } finally {
            updateModalSpec({
              id,
              props: {
                loading: false,
              },
            });
          }
        },
      },
      async onCancelClick() {
        closeModal(id);
        props.onCancelClick?.();
        parentResolve({confirmed: false});
      },
    },
  };
  modalSpecsProxy.value.unshift(modalSpec);
  return promise;
}

export function useOnClickOpenModalCallback(props: ModalSpecProps, dependencies: DependencyList) {
  // we won't know what these dependencies are
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(onClickOpenModal(props), dependencies);
}

export const onClickOpenModal = (props: ModalSpecProps) => {
  const onClick: MouseEventHandler = async (e) => {
    e.stopPropagation?.();
    return await openModal(props).catch((e) => {
      // make it so there's no unhandled rejection such that this error does not get forwarded to the unhandled rejection handler.
      // this error should be getting caught and logged if necessary from `onSubmit` handler
    });
  };
  return onClick;
};

export function closeModal(id: ModalSpec['id']) {
  updateModalSpec({
    id,
    props: {
      animationPhase: 'out',
      loading: false,
    },
  });
  setTimeout(() => {
    modalSpecsProxy.value = modalSpecsProxy.value.filter((modalSpec) => {
      return modalSpec.id !== id;
    });
  }, AnimateOutDurationMs);
}

const ModalContext = observer(function ModalContext(props: {children: ReactNode}) {
  const {children} = props;
  const modalSpecs = useValtioSnapshot(modalSpecsProxy);
  return (
    <>
      <div className="ModalContext relative">
        {modalSpecs.map((modalSpec) => {
          const {id} = modalSpec;
          return (
            <Modal
              key={id}
              {...(modalSpec.props as Readonly<ModalProps>)}
            />
          );
        })}
      </div>
      {children}
    </>
  );
});

export default ModalContext;
