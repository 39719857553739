import {LivanTooltip} from '@/components/core/LivanTooltip';
import FormInput from '@/components/form/FormInput';
import InputDescription from '@/components/form/InputDescription';
import InputErrors from '@/components/form/InputErrors';
import InputLabel, {type InputLabelProps} from '@/components/form/InputLabel';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  type PopoverPanel,
} from '@headlessui/react';
import {observer} from 'mobx-react-lite';

import clsx from 'clsx';
import {CheckIcon, ChevronDownIcon, type LucideIcon} from 'lucide-react';
import {useCallback, useEffect, type ComponentProps} from 'react';

export type SelectInputOption<T> = {
  value: T;
  description?: string;
  notSelectable?: boolean; // useful for dropdown menus that are used for transitions and you don't want to be able to select the default option
} & XOR<
  {label: string},
  {
    Component: React.ComponentType<any>;
    componentProps: any;
  }
>;

type SelectInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'name' | 'value' | 'required' | 'defaultValue'
> & {
  inputClassName?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  disabledTooltipAnchor?: ComponentProps<typeof PopoverPanel>['anchor'];
  IconComponent?: LucideIcon;
  description?: string;
  options: SelectInputOption<any>[];
  InfoTooltipContentComponent?: React.ComponentType<any>;
  infoTooltipContentComponentProps?: any;
} & Pick<InputLabelProps, 'name' | 'label' | 'subLabel'>;

export default FormInput<SelectInputProps, any, HTMLInputElement>({
  defaultValue: null,
  // @ts-expect-error not sure how to fix this
  Component: observer(function SelectInput(props) {
    const {
      className,
      inputClassName,
      errors,
      IconComponent,
      label,
      subLabel,
      name,
      onChange,
      onBlur,
      required,
      validator,
      disabled,
      disabledTooltip,
      disabledTooltipAnchor,
      onClick,
      type = 'text',
      onInputRef,
      value,
      defaultValue,
      options,
      description,
      InfoTooltipContentComponent,
      infoTooltipContentComponentProps,
      ...inputProps
    } = props;

    const handleChange = useCallback(
      function (option: SelectInputOption<any>) {
        onChange({
          value: option.value,
          hasInteracted: true,
        });
      },
      [onChange],
    );

    let selectedOption = options.find((option) => {
      return option.value === value;
    });
    if (!selectedOption && defaultValue != null) {
      // if we couldn't find an option from whatever the value was, assume that the options changed and we should instead be using the defaultValue instead of actual value
      selectedOption = options.find((option) => {
        return option.value === defaultValue;
      });
    }

    useEffect(() => {
      if (selectedOption && selectedOption.value !== value) {
        onChange({
          value: selectedOption.value,
          hasInteracted: false,
        });
      }
    }, [selectedOption, value, onChange]);

    const InputWrapperComponent = disabled && disabledTooltip ? LivanTooltip : 'div';
    const inputWrapperProps =
      InputWrapperComponent === LivanTooltip
        ? {
            contentClassName: 'w-full',
            disabledTooltip,
            anchor: disabledTooltipAnchor,
          }
        : {};

    return (
      <Listbox
        as="div"
        className={clsx(className, 'flex flex-col gap-1')}
        value={value}
        onChange={handleChange}
      >
        <InputLabel
          name={name}
          label={label}
          subLabel={subLabel}
          InfoTooltipContentComponent={InfoTooltipContentComponent}
          infoTooltipContentComponentProps={infoTooltipContentComponentProps}
          hasErrors={errors.length > 0}
          required={required}
        />
        <div className={clsx(label && 'mt-1', 'relative')}>
          <InputWrapperComponent
            content={disabledTooltip}
            {...inputWrapperProps}
          >
            <ListboxButton
              id={name}
              role="listbox"
              disabled={disabled}
              className={clsx(
                'grid w-full grid-cols-1 rounded-md bg-white py-1.5 px-2 text-left text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-livan-black',
                disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer',
              )}
            >
              <span className="col-start-1 row-start-1 flex items-center gap-3 pr-6">
                {selectedOption?.label && (
                  <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                    {selectedOption.label}
                  </span>
                )}
                {selectedOption?.Component && (
                  <selectedOption.Component
                    {...selectedOption.componentProps}
                    value={selectedOption.value}
                  />
                )}
              </span>
              <ChevronDownIcon
                aria-hidden="true"
                className="col-start-1 row-start-1 size-5 self-center justify-self-end text-gray-500 sm:size-4"
              />
            </ListboxButton>
          </InputWrapperComponent>

          <ListboxOptions
            transition
            className="absolute z-50 mt-1 !max-h-[400px] w-[var(--button-width)] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
            anchor="bottom"
          >
            {options
              .map((option) => {
                if (option.notSelectable) {
                  return null;
                }
                return (
                  <ListboxOption
                    key={option.value}
                    value={option}
                    className="group relative cursor-pointer select-none p-2 data-[focus]:bg-livan-black/10 data-[focus]:outline-none flex items-center"
                    data-value={option.value}
                  >
                    <div className="flex items-center gap-2 flex-1">
                      {option.label && (
                        <span className="block truncate font-normal group-data-[selected]:font-semibold">
                          {option.label}
                        </span>
                      )}
                      {option.Component && (
                        <option.Component
                          {...option.componentProps}
                          value={option.value}
                        />
                      )}
                      {option.description && (
                        <span className="block truncate text-sm text-gray-500">
                          {option.description}
                        </span>
                      )}
                    </div>

                    {option.value === value && <CheckIcon className="size-5 shrink-0" />}
                  </ListboxOption>
                );
              })
              .filter(Boolean)}
          </ListboxOptions>
        </div>
        <InputDescription description={description} />
        <InputErrors
          errors={errors}
          name={name}
        />
      </Listbox>
    );
  }),
});
