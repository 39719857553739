import type {AnimationPhase} from '@/components/core/Animation';
import Button from '@/components/core/Button';
import LivanLink from '@/components/core/LivanLink';
import type {FormSpec} from '@/components/form/FormTypes';
import LivanForm, {type LivanFormProps} from '@/components/form/LivanForm';
import {Dialog, DialogBackdrop, DialogPanel, DialogTitle} from '@headlessui/react';

import clsx from 'clsx';
import {XIcon} from 'lucide-react';
import {observer} from 'mobx-react-lite';
import React, {useCallback, useMemo, type FC, type ReactNode} from 'react';

type ModalConfig = {
  titleClassName: string;
  iconProps: {
    className: string;
  };
  iconWrapperProps: {
    className: string;
  };
};

const configsByStyleType: Record<StyleType, ModalConfig> = {
  default: {
    titleClassName: 'bg-livan-black text-white',
    iconProps: {
      className: 'text-green-600',
    },
    iconWrapperProps: {
      className: 'bg-green-100',
    },
  },
  warning: {
    titleClassName: 'bg-livan-black text-white',
    iconProps: {
      className: 'text-yellow-600',
    },
    iconWrapperProps: {
      className: 'bg-yellow-100',
    },
  },
  destructive: {
    titleClassName: 'bg-livan-black text-white',
    iconProps: {
      className: 'text-red-600',
    },
    iconWrapperProps: {
      className: 'bg-red-100',
    },
  },
};

export type ModalProps = {
  styleType?: StyleType;
  className?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  widthClassName?: string;
  onCancelClick?: () => void;
  formSpec: FormSpec;
  extraData?: LivanFormProps['extraData'];
  formSpecDefaultValues?: LivanFormProps['formSpecDefaultValues'];
  cancelButtonText?: string;
  animationPhase: AnimationPhase;
  hideButtons?: boolean;
  loading?: boolean;
} & XOR<
  {
    content?: ReactNode | (() => ReactNode);
  },
  {
    BodyComponent?: FC<any>;
    bodyComponentProps?: Record<string, any>;
  }
> &
  XOR<
    {title: string},
    {
      TitleComponent?: FC<any>;
      titleComponentProps?: Record<string, any>;
    }
  >;

export type ModalType = keyof typeof configsByStyleType;

const Modal = observer(function Modal(props: ModalProps) {
  const {
    className,
    styleType = 'default',
    onCancelClick,
    animationPhase,
    loading,
    formSpec,
    hideButtons = false,
    content,
    widthClassName,
    size,
    ...bodyProps
  } = props;

  const handleCancelClick = useCallback(
    function () {
      onCancelClick?.();
    },
    [onCancelClick],
  );
  const onDialogClose = useCallback(
    function () {
      if (loading) {
        return;
      }
      onCancelClick?.();
    },
    [loading, onCancelClick],
  );

  const globalWidthClassName = useMemo(() => {
    if (!widthClassName) {
      return undefined;
    }
    const widthClassNameParts = widthClassName.split(' ');
    const doesNotHaveGlobalWidth = widthClassNameParts.every((part) => {
      return part.includes(':');
    });
    return doesNotHaveGlobalWidth ? 'w-full' : undefined;
  }, [widthClassName]);

  return (
    <Dialog
      open
      role="dialog"
      onClose={onDialogClose}
      className={clsx('Modal', 'relative z-40', className)}
    >
      <DialogBackdrop
        transition
        className={clsx(
          'fixed inset-0 bg-livan-black/75',
          animationPhase && `Modal-animating-${animationPhase}`,
          animationPhase === 'in' && 'animate-fade-in',
          animationPhase === 'out' && 'animate-fade-out',
        )}
      />

      <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={clsx(
              'ModalBody relative rounded-lg bg-white text-left shadow-xl sm:my-8 max-w-full sm:min-w-[450px]',
              widthClassName || 'sm:w-auto',
              globalWidthClassName,
              size === 'xs' && 'sm:w-[400px]',
              size === 'sm' && 'sm:w-[500px]',
              size === 'md' && 'sm:w-[600px]',
              size === 'lg' && 'sm:w-[700px]',
              animationPhase && `DialogPanel-animating-${animationPhase}`,
              animationPhase === 'in' && 'animate-pop-in',
              animationPhase === 'out' && 'animate-pop-out',
            )}
          >
            <ModalBodyDefault
              styleType={styleType}
              hideButtons={hideButtons}
              formSpec={formSpec}
              onCancelClick={onCancelClick && handleCancelClick}
              loading={loading}
              content={content}
              {...bodyProps}
            />
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
});

export default Modal;

type ModalBodyDefaultProps = {
  title?: string;
  TitleComponent?: FC;
  titleComponentProps?: Record<string, any>;
  styleType?: StyleType;
  formSpec: FormSpec;
  extraData?: LivanFormProps['extraData'];
  formSpecDefaultValues?: LivanFormProps['formSpecDefaultValues'];
  onCancelClick?: () => void;
  cancelButtonText?: string;
  loading?: boolean;
  hideButtons?: boolean;
  content?: ReactNode | (() => ReactNode);
  BodyComponent?: FC;
  bodyComponentProps?: Record<string, any>;
};

const modalBodyDefaultSectionPadding = 'py-2 sm:py-3 px-3 sm:px-4';

const ModalBodyDefault = observer(function ModalBodyDefault(props: ModalBodyDefaultProps) {
  const {
    styleType = 'default',
    title,
    TitleComponent,
    titleComponentProps,
    onCancelClick,
    cancelButtonText = 'Cancel',
    loading = false,
    content,
    formSpec,
    extraData,
    formSpecDefaultValues,
    hideButtons = false,
    BodyComponent,
    bodyComponentProps,
  } = props;

  const {iconProps, iconWrapperProps, titleClassName} = configsByStyleType[styleType];

  const renderFormBody = useCallback<NonNullable<LivanFormProps['renderBody']>>(
    function ({Inputs, inputProps, disabled, loading, inputSpecs}) {
      return (
        <React.Fragment>
          <div
            className={clsx(
              'bg-livan-black flex gap-2 items-center rounded-t-lg',
              titleClassName,
              modalBodyDefaultSectionPadding,
            )}
          >
            <DialogTitle
              as="h3"
              className="flex-1"
            >
              {title}
              {TitleComponent ? <TitleComponent {...titleComponentProps} /> : null}
            </DialogTitle>
            {onCancelClick && (
              <LivanLink
                role="button"
                type="link-light"
                className="shrink-0 size-6"
                onClick={onCancelClick}
              >
                <XIcon
                  size={24}
                  color="white"
                />
                <span className="sr-only">Close</span>
              </LivanLink>
            )}
          </div>
          <div
            className={clsx(
              'flex flex-col gap-4 overflow-x-auto scrollbar-gutter-stable',
              modalBodyDefaultSectionPadding,
              hideButtons && 'rounded-b-lg',
            )}
          >
            {BodyComponent ? (
              <BodyComponent
                {...extraData}
                {...bodyComponentProps}
              />
            ) : typeof content === 'function' ? (
              <div>{content()}</div>
            ) : content ? (
              <div>{content}</div>
            ) : null}
            {inputSpecs.length > 0 && (
              <div>
                <Inputs {...inputProps} />
              </div>
            )}
          </div>
          {!hideButtons && (
            <div
              className={clsx(
                'bg-gray-100 flex gap-2 justify-end rounded-b-lg',
                modalBodyDefaultSectionPadding,
              )}
            >
              {onCancelClick && (
                <Button
                  disabled={loading}
                  flavor="link"
                  onClick={onCancelClick}
                >
                  {cancelButtonText}
                </Button>
              )}
              <Button
                type="submit"
                loading={loading}
                disabled={disabled}
                styleType={styleType}
              >
                {formSpec.submitText || 'Confirm'}
              </Button>
            </div>
          )}
        </React.Fragment>
      );
    },
    [
      title,
      content,
      BodyComponent,
      bodyComponentProps,
      cancelButtonText,
      onCancelClick,
      extraData,
      styleType,
      formSpec.submitText,
      hideButtons,
      titleClassName,
      TitleComponent,
      titleComponentProps,
    ],
  );

  return (
    <LivanForm
      formSpec={formSpec}
      extraData={extraData}
      formSpecDefaultValues={formSpecDefaultValues}
      renderBody={renderFormBody}
    />
  );
});
