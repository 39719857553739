import type {ButtonProps} from '@/components/core/Button';
import DateInput from '@/components/form/DateInput';
import DateTimeInput from '@/components/form/DateTimeInput';
import SelectInput from '@/components/form/SelectInput';
import SwitchInput from '@/components/form/SwitchInput';
import TextareaInput from '@/components/form/TextareaInput';
import TextInput from '@/components/form/TextInput';
import type {PopoverPanel} from '@headlessui/react';
import type {Params} from 'react-router';
import type {ComponentProps, Dispatch, SetStateAction} from 'react';
import type {ZodTypeAny} from 'zod';
import ComboboxInput from '@/components/form/ComboboxInput';

export type OnInputChange<V> = (inputValue: InputValue<V>) => void;

export type InputSpecs = (InputSpec<any> | InputSpecsRow)[];

export type FormSpec = {
  inputSpecs?: InputSpecs;
  getInputSpecs?(
    state: Record<string, any>,
    formValues: Record<string, InputValue<any>>,
  ): InputSpecs;
  defaultGetInputSpecsState?: Record<string, any>;
  onSubmit?(
    values: Record<string, any>,
    options: {
      params: Readonly<Params<string>>;
      searchParams: URLSearchParams;
      extraData?: Record<string, any>;
    },
  ): void | Promise<void | {ignoreFormReset: true}>;
  allowNavigationOnIncompleteForm?: boolean;
  retainValuesOnSubmit?: string[];
  sanitizeValues?(values: Record<string, any>): Record<string, any>;
  submitText?: string;
  submitButtonFullWidth?: boolean;
  validate?: (args: {extraData: any; formData: Record<string, any>}) => {
    error?: string;
    inputErrors?: Record<string, string[]>;
  };
};

export const InputComponentsByType = {
  text: TextInput,
  textarea: TextareaInput,
  password: TextInput,
  datetime: DateTimeInput,
  date: DateInput,
  switch: SwitchInput,
  select: SelectInput,
  combobox: ComboboxInput,
};

export type InputSpecsRow = {
  row: InputSpec<any>[];
};

export type InputSpecType = keyof typeof InputComponentsByType;

export type InputSpec<V> =
  | ({
      name: HTMLInputElement['name'];
      label: string;
      hideErrors?: boolean;
      subLabel?: string;
      description?: string;
      disabled?: boolean;
      disabledTooltip?: string;
      disabledTooltipAnchor?: ComponentProps<typeof PopoverPanel>['anchor'];
      validator: ZodTypeAny;
      defaultValue?: V;
      InfoTooltipContentComponent?: React.ComponentType<any>;
      infoTooltipContentComponentProps?: ComponentProps<any>;
      serialize?: (value: V) => V;
      deserialize?: (value: V) => V;
    } & (
      | {
          type: 'text' | 'password';
          buttonText?: string;
          autoComplete?: HTMLInputElement['autocomplete'];
          placeholder?: HTMLInputElement['placeholder'];
          spellCheck?: HTMLInputElement['spellcheck'];
          autoCapitalize?: HTMLInputElement['autocapitalize'];
          onClick?: React.MouseEventHandler<HTMLButtonElement>;
          onButtonClick?: ButtonProps['onClick'];
          buttonDisabled?: boolean;
          ButtonIconComponent?: React.ForwardRefExoticComponent<
            React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>
          >;
          IconComponent?: React.ForwardRefExoticComponent<
            React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>
          >;
        }
      | {
          type: 'switch';
        }
      | ({
          type: 'select';
        } & Pick<ComponentProps<typeof SelectInput>, 'options'>)
      | ({
          type: 'combobox';
        } & Pick<ComponentProps<typeof ComboboxInput>, 'options'>)
      | {
          type: 'textarea';
          rows?: number;
          autoComplete?: HTMLTextAreaElement['autocomplete'];
          spellCheck?: HTMLTextAreaElement['spellcheck'];
          autoCapitalize?: HTMLTextAreaElement['autocapitalize'];
        }
      | ({
          type: 'datetime';
        } & Pick<ComponentProps<typeof DateTimeInput>, 'min' | 'max'>)
      | ({
          type: 'date';
        } & Pick<ComponentProps<typeof DateInput>, 'min' | 'max'>)
    ))
  | {
      name: HTMLInputElement['name'];
      label: string;
      type: 'button';
      content: ButtonProps['content'];
      onClick: ButtonProps['onClick'];
    };

export type InputValue<V> = {
  name: string;
  value: V;
  errors: string[];
  hasChanged: boolean;
  hasInteracted: boolean;
};
