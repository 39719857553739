import type {InputSpecType, OnInputChange} from '@/components/form/FormTypes';
import {useEffectOnce} from '@/utils/react';
import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
  type FocusEventHandler,
  type Ref,
} from 'react';

import {type ForwardedRef, type ForwardRefRenderFunction} from 'react';
import {z, type ZodTypeAny} from 'zod';

type FormInputProps<V> = {
  name: HTMLInputElement['name'];
  label?: string;
  type?: any;
  defaultValue?: V;
  className?: string;
  onChange: OnInputChange<V>;
  validator?: ZodTypeAny;
  required?: HTMLInputElement['required'];
  errors: string[] | undefined;
  serialize?: (value: V) => V;
  deserialize?: (value: V) => V;
};

type OnChange<V> = (args: {value: V; hasInteracted: boolean}) => void;

type ComponentProps<V, RefType extends HTMLInputElement | HTMLTextAreaElement> = Omit<
  FormInputProps<V>,
  'onChange' | 'errors'
> & {
  onChange: OnChange<V>;
  onInputRef: Ref<RefType>;
  onBlur: FocusEventHandler<RefType>;
  errors: string[];
  value: V;
};

export type FormInputRef<V> = {
  focus(): void;
  blur(): void;
  reset(): void;
  validate(): void;
  commit(): void;
  setValue(value: V): void;
};

// eslint-disable-next-line livan/mobx-missing-observer
export default function FormInput<P, V, RefType extends HTMLInputElement | HTMLTextAreaElement>({
  Component,
  defaultValue: inputDefaultValue,
}: {
  Component: ForwardRefRenderFunction<FormInputRef<V>, P & ComponentProps<V, RefType>>;
  defaultValue: V;
}) {
  return observer(
    forwardRef(function FormInputWrapper(
      props: P & FormInputProps<V>,
      ref: ForwardedRef<FormInputRef<V>>,
    ) {
      const inputRef = useRef<HTMLInputElement>(null);

      const {type, name, validator, onChange, errors, label, serialize, deserialize} = props;

      let {defaultValue: _defaultValue = inputDefaultValue} = props;
      // if we're passing in a default value directly from the db, they will be `null` when text inputs need them to be empty strings
      if (_defaultValue === null) {
        const inputSpecType = type as InputSpecType; // this is what it actually is, but there's a circular reference preventing this from being typed on the props directly
        if (
          inputSpecType === 'text' ||
          inputSpecType === 'textarea' ||
          inputSpecType === 'password'
        ) {
          _defaultValue = '' as V;
        } else if (inputSpecType === 'switch') {
          _defaultValue = false as V;
        }
      }

      const required = validator && !validator?.isOptional();

      const defaultDisplayValue = deserialize ? deserialize(_defaultValue) : _defaultValue;
      const [displayValue, setDisplayValue] = useState<V>(defaultDisplayValue);

      useEffect(() => {
        setDisplayValue(deserialize ? deserialize(_defaultValue) : _defaultValue);
      }, [_defaultValue, deserialize]);

      const serializeValue = useCallback(
        (value: V): V => {
          return serialize ? serialize(value) : value;
        },
        [serialize],
      );

      useImperativeHandle(ref, () => {
        return {
          focus() {
            inputRef.current?.focus();
          },
          blur() {
            inputRef.current?.blur();
          },
          reset() {
            const newDisplayValue = defaultDisplayValue;
            setDisplayValue(newDisplayValue);

            const serializedValue = serializeValue(newDisplayValue);
            const {value: validatedValue, errors} = validate(serializedValue);

            onChange?.({
              name,
              value: validatedValue,
              hasChanged: false,
              errors,
              hasInteracted: false,
            });
          },
          validate() {
            const serializedValue = serializeValue(displayValue);
            const {value: validatedValue, errors} = validate(serializedValue);

            onChange?.({
              name,
              value: validatedValue,
              hasChanged: false,
              errors,
              hasInteracted: true,
            });
          },
          commit() {
            const serializedValue = serializeValue(displayValue);
            const {value: validatedValue} = validate(serializedValue); // just for transformations, ignore errors

            onChange?.({
              name,
              value: validatedValue,
              hasChanged: false,
              errors: [],
              hasInteracted: false,
            });
          },
          setValue(value: V) {
            const newDisplayValue = deserialize ? deserialize(value) : value;
            handleChange({
              value: newDisplayValue,
              hasInteracted: true,
            });
          },
        };
      });

      const hasChanged = useMemo(() => {
        return defaultDisplayValue !== displayValue;
      }, [defaultDisplayValue, displayValue]);

      const validate = useCallback(
        function (value: V) {
          const errors: string[] = [];
          if (validator) {
            const result =
              !required && value === ''
                ? validator.or(z.literal('')).safeParse(value)
                : validator.safeParse(value);
            if (result?.error) {
              errors.push(
                ...result.error.issues.map(({message}) => {
                  return message;
                }),
              );
            }
            if (result?.data != null) {
              value = result.data;
            }
          }

          if (!errors.length && typeof value === 'string' && value.length <= 0 && required) {
            errors.push(`${label || 'Field'} is required`);
          }
          return {value, errors};
        },
        [label, required, validator],
      );

      const handleChange = useCallback<OnChange<V>>(
        function ({value, hasInteracted = true}) {
          setDisplayValue(() => {
            return value;
          });

          const serializedValue = serializeValue(value);
          const {value: validatedValue, errors} = validate(serializedValue);

          const defaultSerializedValue = serializeValue(defaultDisplayValue);
          const hasChanged = defaultSerializedValue !== serializedValue; // must compute this out of band since we won't get a re-render to update the `value`

          onChange?.({
            name,
            value: validatedValue,
            hasChanged,
            hasInteracted,
            errors,
          });
        },
        [onChange, name, validate, defaultDisplayValue, serializeValue],
      );

      const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
        function (event) {
          if ((event.relatedTarget as any)?.href && event.relatedTarget?.role !== 'button') {
            // if we're navigating away to a tag with an href (likely an anchor tag), don't let validation kick in because it causes UI jank
            return;
          }

          const serializedValue = serializeValue(displayValue);
          const {value: validatedValue, errors} = validate(serializedValue);

          onChange?.({
            name,
            value: validatedValue,
            hasChanged,
            hasInteracted: true,
            errors,
          });
        },
        [onChange, hasChanged, name, validate, displayValue, serializeValue],
      );

      useEffectOnce(() => {
        const serializedValue = serializeValue(defaultDisplayValue);
        const {value: validatedValue, errors} = validate(serializedValue);

        onChange?.({
          name,
          value: validatedValue,
          hasChanged: false,
          hasInteracted: false,
          errors,
        });
      });

      const {className, serialize: _serialize, deserialize: _deserialize, ...rest} = props;

      return (
        <div className={clsx('FormInput', className)}>
          {/* @ts-expect-error not sure how to make tsc happy for dynamic `P` */}
          <Component
            {...rest}
            required={required}
            onInputRef={inputRef as Ref<RefType>}
            onChange={handleChange}
            onBlur={handleBlur as FocusEventHandler<RefType>}
            errors={errors || []}
            value={displayValue}
          />
        </div>
      );
    }),
  );
}
