import {LivanTooltip} from '@/components/core/LivanTooltip';
import FormInput from '@/components/form/FormInput';
import InputDescription from '@/components/form/InputDescription';
import InputErrors from '@/components/form/InputErrors';
import InputLabel from '@/components/form/InputLabel';
import type {PopoverPanel} from '@headlessui/react';
import {observer} from 'mobx-react-lite';

import clsx from 'clsx';
import type {LucideIcon} from 'lucide-react';
import {
  useCallback,
  type ChangeEventHandler,
  type ComponentProps,
  type KeyboardEventHandler,
} from 'react';

type DateTimeInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'name' | 'value' | 'required' | 'defaultValue' | 'type'
> &
  Pick<
    ComponentProps<typeof InputLabel>,
    'InfoTooltipContentComponent' | 'infoTooltipContentComponentProps'
  > & {
    type: 'datetime' | 'date';
    label: string;
    description?: string;
    subLabel?: string;
    inputClassName?: string;
    disabled?: boolean;
    disabledTooltip?: string;
    disabledTooltipAnchor?: ComponentProps<typeof PopoverPanel>['anchor'];
    IconComponent?: LucideIcon;
  };

function convertInputToUTC(inputValue: string) {
  if (!inputValue) return null;
  const localDate = new Date(inputValue);
  const utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
  return utcDate;
}

function formatUTCDateForInput(date: Date) {
  const pad = (num: number) => {
    return String(num).padStart(2, '0');
  };
  const year = date.getUTCFullYear();
  const month = pad(date.getUTCMonth() + 1); // getUTCMonth is zero-indexed
  const day = pad(date.getUTCDate());
  const hours = pad(date.getUTCHours());
  const minutes = pad(date.getUTCMinutes());
  const seconds = pad(date.getUTCSeconds());
  const milliseconds = pad(date.getUTCMilliseconds());
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
}

function formatDateForInput(props: {type: 'datetime' | 'date'; value: Date}) {
  const {type, value: date} = props;
  const pad = (num: number) => {
    return String(num).padStart(2, '0');
  };
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are zero-indexed
  const day = pad(date.getDate());

  if (type === 'date') {
    return `${year}-${month}-${day}`;
  }
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = pad(date.getMilliseconds());
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export default FormInput<DateTimeInputProps, Date | undefined, HTMLInputElement>({
  defaultValue: undefined,
  // @ts-expect-error not sure how to fix this
  Component: observer(function TextInput(props) {
    const {
      className,
      inputClassName,
      errors,
      IconComponent,
      label,
      subLabel,
      type,
      name,
      description,
      onChange,
      onBlur,
      required,
      validator,
      disabled,
      disabledTooltip,
      disabledTooltipAnchor,
      onClick,
      onInputRef,
      value,
      defaultValue,
      InfoTooltipContentComponent,
      infoTooltipContentComponentProps,
      ...inputProps
    } = props;

    const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(function (event) {
      if (event.key === 'Enter') {
        // don't let enter on input submit the form; must use ctrl+enter as defined in a form
        event.preventDefault();
      }
    }, []);

    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
      function (event) {
        const rawValue = event.target.value;
        const value = new Date(rawValue);
        onChange({
          value,
          hasInteracted: false, // wait for blur handler to mark as interacted
        });
      },
      [onChange],
    );

    const InputWrapperComponent = disabled && disabledTooltip ? LivanTooltip : 'div';

    return (
      <div className={clsx(className, 'flex flex-col w-full gap-1')}>
        <InputLabel
          name={name}
          label={label}
          subLabel={subLabel}
          InfoTooltipContentComponent={InfoTooltipContentComponent}
          infoTooltipContentComponentProps={infoTooltipContentComponentProps}
          hasErrors={errors.length > 0}
          required={required}
        />
        <InputWrapperComponent
          className={clsx(label && 'mt-1', 'relative flex w-full')}
          content={disabledTooltip}
          anchor={disabledTooltipAnchor}
        >
          {IconComponent && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <IconComponent
                aria-hidden="true"
                size={20}
                color="gray"
              />
            </div>
          )}
          <input
            {...inputProps}
            id={name}
            type={type === 'datetime' ? 'datetime-local' : type}
            name={name}
            ref={onInputRef}
            required={required}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            value={value ? formatDateForInput({type, value}) : ''}
            disabled={disabled}
            className={clsx(
              'rounded-md',
              type === 'datetime' ? 'min-w-44' : 'min-w-36', // need min-w because safari does not have a placeholder so this will shrink to a really small weird width otherwise
              IconComponent && 'pl-10',
              inputClassName,
              disabled && 'bg-gray-200',
              disabled && onClick && 'cursor-pointer',
              disabled && !onClick && 'cursor-not-allowed ',
              'px-2.5 text-black shadow-sm sm:leading-6 border-0 py-1.5 ring-1 ring-inset ring-gray-300',
              disabled
                ? 'focus:ring-0 focus:ring-livan-black/0 focus-visible:ring-0 focus-visible:border-0'
                : 'focus:ring-livan-black focus:ring-2 focus:ring-inset',
              errors.length && 'ring-red-700 focus:ring-red-700',
            )}
            aria-invalid={!!errors.length}
            aria-describedby={errors.length ? `${name}-error` : undefined}
          />
        </InputWrapperComponent>
        <InputDescription description={description} />
        <InputErrors
          errors={errors}
          name={name}
        />
      </div>
    );
  }),
});
