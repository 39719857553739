import {observer} from 'mobx-react-lite';
import clsx from 'clsx';
import type {BaseComponentProps} from '@/components/types';
import type {FC} from 'react';

type Props = BaseComponentProps & {
  description: string | undefined;
};

const InputDescription: FC<Props> = observer(function InputDescription(props) {
  const {className, description} = props;

  return (
    description && <div className={clsx(className, 'text-sm text-gray-800')}>{description}</div>
  );
});

export default InputDescription;
