import clsx from 'clsx';
import type {BaseComponentProps} from '@/components/types';
import type {FC} from 'react';
import React from 'react';
import {LivanTooltip} from '@/components/core/LivanTooltip';
import {InfoIcon} from 'lucide-react';
import {observer} from 'mobx-react-lite';

export type InputLabelProps = BaseComponentProps & {
  orientation?: 'vertical' | 'horizontal';
  name: string;
  label: string | undefined;
  InfoTooltipContentComponent?: React.ComponentType<any>;
  infoTooltipContentComponentProps?: Record<string, any>;
  subLabel: string | undefined;
  hasErrors: boolean;
  required: boolean | undefined;
};

const InputLabel = observer(_InputLabel) as typeof _InputLabel;
function _InputLabel(props: InputLabelProps) {
  const {
    className,
    orientation = 'horizontal',
    name,
    label,
    subLabel,
    hasErrors,
    required,
    InfoTooltipContentComponent,
    infoTooltipContentComponentProps,
  } = props;

  return (
    label && (
      <label
        htmlFor={name}
        className={clsx(
          className,
          'font-medium flex',
          orientation === 'vertical' ? 'flex-col gap-1' : 'flex-row items-center gap-2',
          hasErrors && 'text-red-700',
        )}
      >
        <span className="shrink-0">
          {label} {required && <span className="text-red-700">*</span>}
        </span>
        {InfoTooltipContentComponent && (
          <LivanTooltip
            className="shrink-0 cursor-help"
            Component={InfoTooltipContentComponent}
            componentProps={infoTooltipContentComponentProps}
            anchor="right top"
          >
            <InfoIcon className="size-4" />
          </LivanTooltip>
        )}
        {subLabel && (
          <div
            className={clsx('text-sm', !hasErrors && 'text-gray-600', hasErrors && 'text-red-700')}
          >
            {subLabel}
          </div>
        )}
      </label>
    )
  );
}

export default InputLabel;
