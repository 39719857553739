import Button from '@/components/core/Button';
import {LivanTooltip} from '@/components/core/LivanTooltip';
import FormInput from '@/components/form/FormInput';
import InputDescription from '@/components/form/InputDescription';
import InputErrors from '@/components/form/InputErrors';
import {observer} from 'mobx-react-lite';
import InputLabel from '@/components/form/InputLabel';
import type {ButtonProps, PopoverPanel} from '@headlessui/react';

import clsx from 'clsx';
import type {LucideIcon} from 'lucide-react';
import {
  useCallback,
  type ChangeEventHandler,
  type ComponentProps,
  type KeyboardEventHandler,
} from 'react';

type TextareaInputProps = Omit<
  React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
  'onChange' | 'name' | 'value' | 'required' | 'defaultValue' | 'type'
> & {
  inputClassName?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  disabledTooltipAnchor?: ComponentProps<typeof PopoverPanel>['anchor'];
  IconComponent?: LucideIcon;
} & Pick<
    ComponentProps<typeof InputLabel>,
    'InfoTooltipContentComponent' | 'infoTooltipContentComponentProps'
  > &
  XOR<
    {
      buttonText?: string;
      ButtonIconComponent?: LucideIcon;
      onButtonClick?: ButtonProps['onClick'];
      buttonDisabled?: boolean;
      alignButtonLeft: boolean;
    },
    {
      label: string;
      subLabel?: string;
      description?: string;
    }
  >;

export default FormInput<TextareaInputProps, string, HTMLTextAreaElement>({
  defaultValue: '',
  // @ts-expect-error not sure how to fix this
  Component: observer(function TextareaInput(props: any) {
    const {
      className,
      inputClassName,
      errors,
      IconComponent,
      label,
      subLabel,
      buttonText,
      alignButtonLeft,
      name,
      description,
      onChange,
      onBlur,
      required,
      validator,
      disabled,
      disabledTooltip,
      disabledTooltipAnchor,
      onClick,
      onButtonClick,
      ButtonIconComponent,
      buttonDisabled,
      onInputRef,
      value,
      defaultValue,
      InfoTooltipContentComponent,
      infoTooltipContentComponentProps,
      ...inputProps
    } = props;

    const hasButton = !!(onButtonClick || buttonText || ButtonIconComponent);

    const handleChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
      function (event) {
        const newValue = event.target.value;
        onChange({
          value: newValue,
          hasInteracted: false, // wait for blur handler to mark as interacted
        });
      },
      [onChange],
    );

    const InputWrapperComponent = disabled && disabledTooltip ? LivanTooltip : 'div';

    return (
      <div className={clsx(className, 'flex flex-col w-full gap-1')}>
        <InputLabel
          name={name}
          label={label}
          subLabel={subLabel}
          InfoTooltipContentComponent={InfoTooltipContentComponent}
          infoTooltipContentComponentProps={infoTooltipContentComponentProps}
          hasErrors={errors.length > 0}
          required={required}
        />
        <InputWrapperComponent
          className={clsx(label && 'mt-1', 'relative flex w-full')}
          content={disabledTooltip}
          anchor={disabledTooltipAnchor}
        >
          {IconComponent && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <IconComponent
                aria-hidden="true"
                size={20}
                color="gray"
              />
            </div>
          )}
          <textarea
            {...inputProps}
            id={name}
            name={name}
            ref={onInputRef}
            required={required}
            onChange={handleChange}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            className={clsx(
              IconComponent && 'pl-10',
              inputClassName,
              disabled && 'bg-gray-200',
              disabled && onClick && 'cursor-pointer',
              disabled && !onClick && 'cursor-not-allowed ',
              hasButton
                ? alignButtonLeft
                  ? 'rounded-r-md flex-1'
                  : 'rounded-l-md flex-1'
                : 'rounded-md w-full',
              'px-2.5 text-black shadow-sm sm:leading-6 border-0 py-1.5 ring-1 ring-inset ring-gray-300',
              disabled
                ? 'focus:ring-0 focus:ring-livan-black/0 focus-visible:ring-0 focus-visible:border-0'
                : 'focus:ring-livan-black focus:ring-2 focus:ring-inset',
              errors.length && 'ring-red-700 focus:ring-red-700',
            )}
            aria-invalid={!!errors.length}
            aria-describedby={errors.length ? `${name}-error` : undefined}
          />
        </InputWrapperComponent>
        <InputDescription description={description} />
        <InputErrors
          errors={errors}
          name={name}
        />
      </div>
    );
  }),
});
