import Button from '@/components/core/Button';
import type {FormInputRef} from '@/components/form/FormInput';
import {
  InputComponentsByType,
  type FormSpec,
  type InputValue,
  type OnInputChange,
} from '@/components/form/FormTypes';
import {openModal} from '@/components/modal/ModalContext';
import type {BaseComponentProps} from '@/components/types';
import {requestAnimationFrameAsync} from '@/utils/animation';
import assert from '@/utils/assert';
import {constructErrorMeta, displayClientError} from '@/utils/errors';
import {eventIsSubmit} from '@/utils/keyboard';
import {useAutorunEffect} from '@/utils/mobx';
import {usePrevious} from '@/utils/react';
import {stopPropagationAndPreventDefault} from '@/utils/stopPropagationAndPreventDefault';

import clsx from 'clsx';
import {autorun, computed, observable, runInAction} from 'mobx';
import {Observer, observer, useLocalObservable} from 'mobx-react-lite';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  type ComponentProps,
  type FormEventHandler,
  type ForwardedRef,
  type KeyboardEventHandler,
  type ReactNode,
  type RefCallback,
} from 'react';
import {useBlocker, useParams, useSearchParams} from 'react-router';

type Form = {
  values: FormValues;
  state: FormState;
  disabled: boolean;
};

type FormValues = Record<string, InputValue<any>>;

type FormState = {
  isValid: boolean;
  hasChanged: boolean;
  hasInteracted: boolean;
};

export type LivanFormProps = BaseComponentProps & {
  disableAutoFocus?: boolean;
  renderBody?(args: {
    Inputs: typeof Inputs;
    inputProps: ComponentProps<typeof Inputs>;
    disabled: boolean;
    loading: boolean;
    inputSpecs: NonNullable<FormSpec['inputSpecs']>;
  }): ReactNode;
  formSpec: FormSpec;
  formSpecDefaultValues?: Record<string, any>;
  extraData?: any;
  onSubmitSuccess?(): void;
  onFormChange?({
    formState,
    formValues,
    disabled,
  }: {
    formState: FormState;
    formValues: FormValues;
    disabled: boolean;
  }): void;
  InputsWrapperComponent?: () => ReactNode;
};

export type LivanFormRef = {
  reset(): void;
  commit(): void;
  submit(): void;
  validate(): void;
  setInputValue<V>(name: string, value: V): void;
};

const LivanForm = observer(
  forwardRef(function LivanForm(props: LivanFormProps, ref: ForwardedRef<LivanFormRef>) {
    const {
      className,
      formSpec,
      renderBody,
      onFormChange,
      onSubmitSuccess,
      disableAutoFocus: disableAutofocus,
      formSpecDefaultValues,
      extraData,
    } = props;
    const {
      onSubmit,
      submitText,
      getInputSpecs,
      submitButtonFullWidth,
      defaultGetInputSpecsState,
      sanitizeValues,
      retainValuesOnSubmit,
      allowNavigationOnIncompleteForm,
      validate,
    } = formSpec;
    const [loading, setLoading] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const form = useLocalObservable<Form>(() => {
      return {
        values: observable(
          {},
          {
            deep: true,
          },
        ) as FormValues,
        get state() {
          const state = Object.values(this.values).reduce<FormState>(
            (acc, formValue) => {
              const {errors, hasChanged, hasInteracted} = formValue;
              if (errors.length) {
                acc.isValid = false;
              }
              if (hasChanged) {
                acc.hasChanged = true;
              }
              if (hasInteracted) {
                acc.hasInteracted = true;
              }
              return acc;
            },
            {isValid: true, hasChanged: false, hasInteracted: false},
          );
          return state;
        },
        get disabled() {
          return !this.state.isValid;
        },
      };
    });
    const inputRefsByName = useLocalObservable(() => {
      return {
        value: {} as Record<string, FormInputRef<any>>,
      };
    });
    const params = useParams();
    const [searchParams] = useSearchParams();

    const getSetInputRef = useCallback(
      function <V>(name: string) {
        return (ref: FormInputRef<V>) => {
          runInAction(() => {
            inputRefsByName.value = {
              ...inputRefsByName.value,
              [name]: ref,
            };
          });
        };
      },
      [inputRefsByName],
    );

    const inputSpecsObservable = useLocalObservable(() => {
      return {
        initialInputSpecs: formSpec.inputSpecs,
        state: defaultGetInputSpecsState || {},
        get inputSpecs() {
          return getInputSpecs?.(this.state, form.values) || this.initialInputSpecs || [];
        },
        get namesSet() {
          const namesSet = new Set(
            this.inputSpecs.flatMap((inputSpec) => {
              if ('row' in inputSpec) {
                return inputSpec.row.map(({name}) => {
                  return name;
                });
              }
              return [inputSpec.name];
            }),
          );
          return namesSet;
        },
      };
    });

    useEffect(() => {
      runInAction(() => {
        inputSpecsObservable.initialInputSpecs = formSpec.inputSpecs;
      });
    }, [inputSpecsObservable, formSpec.inputSpecs]);

    const inputSpecs = inputSpecsObservable.inputSpecs;

    useAutorunEffect(() => {
      // form won't fire if we've dynamically removed an input spec, which can leave leftover state in the form which is still validated
      for (const name of Object.keys(form.values)) {
        if (!inputSpecsObservable.namesSet.has(name)) {
          delete form.values[name];
        }
      }
    });

    const shouldBlock = useCallback(
      function ({currentLocation, nextLocation}) {
        return !!(
          !allowNavigationOnIncompleteForm &&
          form.state.hasChanged &&
          !hasSubmitted &&
          currentLocation.pathname !== nextLocation.pathname
        );
      },
      [form.state, hasSubmitted, allowNavigationOnIncompleteForm],
    );
    const blocker = useBlocker(shouldBlock);

    useEffect(() => {
      if (blocker.state === 'blocked') {
        openModal({
          title: 'Unsaved changes',
          content: 'You have unsaved changes. Would you like to discard them and proceed?',
          styleType: 'warning',
          formSpec: {
            submitText: 'Proceed',
            async onSubmit() {
              await requestAnimationFrameAsync();
              blocker.proceed();
            },
          },
          onCancelClick() {
            blocker.reset();
            runInAction(() => {
              Object.values(inputRefsByName.value).forEach((ref) => {
                ref.validate();
              });
            });
          },
        });
      }
    }, [blocker, form.state, inputRefsByName]);

    const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
      async function (event) {
        if (!onSubmit) {
          return;
        }
        stopPropagationAndPreventDefault(event);
        if (loading) {
          return;
        }
        const {disabled} = form;
        if (disabled) {
          Object.values(inputRefsByName.value).forEach((ref) => {
            ref.validate();
          });
          return;
        }
        let values = Object.values(form.values).reduce((acc, {name, value}) => {
          if (value !== '') {
            acc[name] = value;
          }
          return acc;
        }, {});
        if (sanitizeValues) {
          values = sanitizeValues(values);
        }
        if (validate) {
          const {error, inputErrors} = validate({
            formData: values,
            extraData,
          });
          if (inputErrors) {
            Object.entries(inputErrors).forEach(([name, errors]) => {
              if (errors?.length) {
                assert(
                  form.values[name],
                  `form did not have field "${name}" but validator errored on it`,
                );
                runInAction(() => {
                  if (form.values[name].errors) {
                    form.values[name].errors.push(...errors);
                  } else {
                    form.values[name].errors = errors;
                  }
                });
              }
            });
          }
        }
        setLoading(true);
        setHasSubmitted(true);
        let result;
        try {
          await runInAction(async () => {
            result = await onSubmit(values, {
              params,
              searchParams,
              extraData: extraData,
            });
          });
          if (!result?.ignoreFormReset) {
            runInAction(() => {
              setHasSubmitted(false);
              Object.entries(inputRefsByName.value).forEach(([name, ref]) => {
                if (retainValuesOnSubmit?.includes(name)) {
                  ref.commit();
                } else {
                  ref.reset();
                }
              });
            });
          }
          onSubmitSuccess?.();
        } catch (e) {
          const error = e as Error;
          const errorMeta = constructErrorMeta(error);
          displayClientError(errorMeta);

          assert(typeof errorMeta !== 'string', 'errorMeta is a string');
          if (errorMeta?.field && Object.entries(errorMeta?.field).length) {
            Object.entries(errorMeta.field).forEach(([name, errors]) => {
              if (errors) {
                assert(
                  form.values[name],
                  `form did not have field "${name}" but validator errored on it`,
                );
                runInAction(() => {
                  form.values[name].errors = errors;
                });
              }
            });
          }
        } finally {
          if (!result?.ignoreFormReset) {
            setLoading(false);
          }
        }
      },
      [
        extraData,
        form,
        validate,
        loading,
        onSubmit,
        inputRefsByName,
        params,
        searchParams,
        onSubmitSuccess,
        sanitizeValues,
        retainValuesOnSubmit,
      ],
    );

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLFormElement>>(
      function (event) {
        if (eventIsSubmit(event)) {
          return handleSubmit(event);
        }
      },
      [handleSubmit],
    );

    const handleInputChange = useCallback<OnInputChange<any>>(
      function (value) {
        runInAction(() => {
          form.values[value.name] = value;
        });
      },
      [form],
    );

    useAutorunEffect(() => {
      onFormChange?.({
        formState: form.state,
        formValues: form.values,
        disabled: form.disabled,
      });
    });

    const inputProps = computed(() => {
      return {
        inputSpecs,
        getInputSpecs,
        getSetInputRef: getSetInputRef,
        onInputChange: handleInputChange,
        formValues: form.values,
        hasSubmitted,
        defaultGetInputSpecsState,
        formSpecDefaultValues,
      };
    }).get();

    useImperativeHandle(ref, () => {
      return {
        reset() {
          runInAction(() => {
            Object.values(inputRefsByName.value).forEach((ref) => {
              ref.reset();
            });
          });
        },
        commit() {
          runInAction(() => {
            Object.values(inputRefsByName.value).forEach((ref) => {
              ref.commit();
            });
          });
        },
        submit() {
          handleSubmit(new Event('submit') as unknown as React.FormEvent<HTMLFormElement>);
        },
        validate() {
          runInAction(() => {
            Object.values(inputRefsByName.value).forEach((ref) => {
              ref.validate();
            });
          });
        },
        setInputValue<V>(name: string, value: V) {
          const inputRef = inputRefsByName.value[name];
          inputRef.setValue(value);
        },
      };
    });

    return (
      <form
        className={clsx(className, 'flex flex-col')}
        onKeyDown={handleKeyDown}
        onSubmit={handleSubmit}
        noValidate
      >
        {renderBody ? (
          renderBody({
            Inputs,
            inputProps,
            inputSpecs,
            disabled: form.disabled,
            loading,
          })
        ) : (
          <div className="flex flex-col gap-2">
            <Inputs
              {...inputProps}
              inputSpecs={inputSpecs}
              disableAutofocus={disableAutofocus}
            />
            {onSubmit && (
              <div>
                <Button
                  type="submit"
                  fullWidth={submitButtonFullWidth}
                  disabled={form.disabled}
                  loading={loading}
                >
                  {submitText || 'Submit'}
                </Button>
              </div>
            )}
          </div>
        )}
      </form>
    );
  }),
);

type InputsProps = {
  inputSpecs: NonNullable<FormSpec['inputSpecs']>;
  disableAutofocus?: boolean;
  getSetInputRef<V>(name: string): RefCallback<FormInputRef<V>>;
  onInputChange: OnInputChange<any>;
  formValues: FormValues;
  hasSubmitted: boolean;
  formSpecDefaultValues: LivanFormProps['formSpecDefaultValues'];
};

const Inputs = observer(function Inputs(props: InputsProps) {
  const {
    onInputChange,
    getSetInputRef,
    formValues,
    hasSubmitted,
    disableAutofocus,
    inputSpecs,
    formSpecDefaultValues,
  } = props;

  return inputSpecs.map((inputSpec, i) => {
    if ('row' in inputSpec) {
      return (
        <InputsRow
          key={`row-${i}`}
          {...props}
          inputSpecs={inputSpec.row}
        />
      );
    }
    const {type, name, ...inputSpecRest} = inputSpec;
    const defaultValue =
      'defaultValue' in inputSpec && inputSpec.defaultValue !== undefined
        ? inputSpec.defaultValue
        : formSpecDefaultValues?.[inputSpec.name];

    if (type === 'button') {
      return (
        // @ts-expect-error not sure how to get `to` to work here with XOR
        <Button
          key={name}
          {...inputSpecRest}
        />
      );
    }

    const InputComponent = InputComponentsByType[type];
    const setInputRef = getSetInputRef(name);
    const errors = hasSubmitted || formValues[name]?.hasInteracted ? formValues[name]?.errors : [];

    return (
      // @ts-expect-error not sure how to fix this with dynamic input `type`s
      <InputComponent
        {...inputSpecRest}
        defaultValue={defaultValue}
        className={clsx((inputSpecRest as any).className, 'flex-1')}
        type={type}
        autoFocus={i === 0 && !disableAutofocus}
        ref={setInputRef}
        name={name}
        key={name}
        onChange={onInputChange}
        errors={errors}
      />
    );
  });
});

const InputsRow = observer(function InputsRow(props: InputsProps) {
  return (
    <div className="Inputs-row flex flex-col sm:flex-row sm:gap-2">
      <Inputs {...props} />
    </div>
  );
});

export default LivanForm;
