import FormInput from '@/components/form/FormInput';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput as HeadlessComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from '@headlessui/react';
import {Check, ChevronsUpDown} from 'lucide-react';

import {useCallback, useMemo, useRef, useState, type ComponentProps} from 'react';
import InputErrors from '@/components/form/InputErrors';
import InputLabel from '@/components/form/InputLabel';
import {observer} from 'mobx-react-lite';
import clsx from 'clsx';
import ArrayUtils from '@/ArrayUtils';
import InputDescription from '@/components/form/InputDescription';
import ViewportStore from '@/state/ViewportStore';

export type ComboboxOption<T> = {
  value: T;
  description?: string;
} & XOR<
  {label: string},
  {
    Component: React.ComponentType<any>;
    componentProps: any;
    slug: string;
    displayValue: string;
  }
>;

type ComboboxInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'name' | 'value' | 'required' | 'defaultValue'
> &
  Pick<
    ComponentProps<typeof InputLabel>,
    'InfoTooltipContentComponent' | 'infoTooltipContentComponentProps'
  > & {
    label: string;
    subLabel?: string;
    description?: string;
    options: ComboboxOption<any>[];
  };

export default FormInput<ComboboxInputProps, any, HTMLInputElement>({
  defaultValue: null,
  // @ts-expect-error not sure how to fix this
  Component: observer(function ComboboxInput(props) {
    const {
      className,
      errors,
      label,
      name,
      subLabel,
      description,
      onChange,
      onBlur,
      autoComplete = 'off',
      required,
      validator,
      onInputRef,
      value,
      options,
      InfoTooltipContentComponent,
      infoTooltipContentComponentProps,
    } = props;

    const [query, setQuery] = useState('');

    const filteredOptions =
      query === ''
        ? options
        : options.filter((option) => {
            return (option.slug || option.label).toLowerCase().includes(query.toLowerCase());
          });

    const inputRef = useRef<HTMLInputElement>(null);

    const handleComboboxClick = (e) => {
      // we want to select all text upon click so that user can clear input with one keystroke if they want
      if (e.target.select) {
        // input itself was clicked
        e.target.select?.();
      } else {
        // button was clicked
        inputRef.current?.select?.();
      }
    };

    const selectedOption = useMemo(() => {
      return (
        filteredOptions.find((option) => {
          return option.value === value;
        }) || null
      );
    }, [filteredOptions, value]);

    const handleChange = useCallback(
      function (option) {
        setQuery('');
        onChange({
          value: option?.value == null ? null : option?.value,
          hasInteracted: true,
        });
      },
      [onChange],
    );

    const handleComboboxChange = useCallback(function (event) {
      setQuery(event.target.value);
    }, []);

    const getDisplayValue = useCallback(function (option) {
      if (option?.value === null) {
        return '';
      }
      return option?.displayValue || option?.label;
    }, []);

    const optionsStyle = useMemo(() => {
      // must compute values dynamically for virtualization to work properly
      return {
        height: Math.min(filteredOptions.length, 10) * 40,
      };
    }, [filteredOptions]);

    return (
      <div className={clsx(className, 'flex flex-col gap-1')}>
        <InputLabel
          name={name}
          label={label}
          subLabel={subLabel}
          InfoTooltipContentComponent={InfoTooltipContentComponent}
          infoTooltipContentComponentProps={infoTooltipContentComponentProps}
          hasErrors={!!errors.length}
          required={required}
        />
        <Combobox
          name={name}
          className={clsx('flex flex-col gap-1', label && 'mt-1')}
          virtual={{options: filteredOptions}}
          value={selectedOption}
          onChange={handleChange}
          onClick={handleComboboxClick}
          immediate
        >
          <div className={clsx('relative')}>
            <HeadlessComboboxInput
              id={name}
              ref={inputRef}
              aria-label={label}
              name={name}
              className={clsx(
                'block w-full rounded-md bg-white py-1.5 pl-3 pr-12 text-base outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-1 focus:-outline-offset-2 focus:outline-livan-black',
                errors.length > 0 && 'outline-red-700',
              )}
              autoComplete={autoComplete}
              onChange={handleComboboxChange}
              displayValue={getDisplayValue}
            />
            <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronsUpDown
                className="size-5 text-gray-400"
                aria-hidden="true"
              />
            </ComboboxButton>

            <ComboboxOptions
              className="absolute z-50 mt-1 w-[var(--input-width)] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black/5 focus:outline-none empty:invisible"
              anchor={ViewportStore.sm ? 'bottom' : 'top'}
              style={optionsStyle}
            >
              {({option}) => {
                return (
                  <ComboboxOption
                    value={option}
                    className="group relative cursor-pointer select-none py-2 pl-3 pr-10 data-[focus]:bg-livan-black/10 data-[focus]:outline-none w-full"
                  >
                    <div className="flex items-center">
                      {option.Component ? (
                        <option.Component
                          {...option.componentProps}
                          className={option.componentProps?.className}
                        />
                      ) : (
                        <span className="truncate group-data-[selected]:font-semibold shrink-0">
                          {option.label}
                        </span>
                      )}
                      {option.description && (
                        <span className="ml-1 truncate text-sm">- {option.description}</span>
                      )}
                    </div>

                    <span className="absolute inset-y-0 right-0 hidden items-center pr-3 text-livan-black group-data-[selected]:flex ">
                      <Check
                        className="size-5"
                        aria-hidden="true"
                      />
                    </span>
                  </ComboboxOption>
                );
              }}
            </ComboboxOptions>
          </div>
        </Combobox>
        <InputDescription description={description} />
        <InputErrors
          errors={errors}
          name={name}
        />
      </div>
    );
  }),
});
