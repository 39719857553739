import FormInput from '@/components/form/FormInput';
import InputErrors from '@/components/form/InputErrors';
import InputLabel from '@/components/form/InputLabel';
import {Switch} from '@headlessui/react';
import {observer} from 'mobx-react-lite';

import clsx from 'clsx';
import {useCallback, type ComponentProps} from 'react';

type SwitchInputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'name' | 'value' | 'required' | 'defaultValue'
> &
  Pick<
    ComponentProps<typeof InputLabel>,
    'InfoTooltipContentComponent' | 'infoTooltipContentComponentProps'
  > & {
    label: string;
    description?: string;
  };

export default FormInput<SwitchInputProps, boolean, HTMLInputElement>({
  defaultValue: false,
  // @ts-expect-error not sure how to fix this
  Component: observer(function SwitchInput(props) {
    const {
      className,
      errors,
      label,
      name,
      description,
      onChange,
      onBlur,
      required,
      validator,
      onInputRef,
      value,
      InfoTooltipContentComponent,
      infoTooltipContentComponentProps,
      ...inputProps
    } = props;

    const handleChange = useCallback(
      function (newValue: boolean) {
        onChange({
          value: newValue,
          hasInteracted: true,
        });
      },
      [onChange],
    );

    return (
      <div className={clsx(className, 'flex flex-col gap-1')}>
        <div className="flex gap-2">
          <InputLabel
            className="flex-1"
            orientation="vertical"
            name={name}
            label={label}
            subLabel={description}
            InfoTooltipContentComponent={InfoTooltipContentComponent}
            infoTooltipContentComponentProps={infoTooltipContentComponentProps}
            hasErrors={errors.length > 0}
            required={required}
          />
          <Switch
            id={name}
            checked={value}
            onChange={handleChange}
            className="ml-2 group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out data-[checked]:bg-green-600"
          >
            <span className="pointer-events-none relative inline-block size-5 rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5">
              <span
                aria-hidden="true"
                className="absolute inset-0 flex size-full items-center justify-center transition-opacity duration-200 ease-in group-data-[checked]:opacity-0 group-data-[checked]:duration-100 group-data-[checked]:ease-out"
              >
                <svg
                  fill="none"
                  viewBox="0 0 12 12"
                  className="size-3 text-gray-400"
                >
                  <path
                    d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <span
                aria-hidden="true"
                className="absolute inset-0 flex size-full items-center justify-center opacity-0 transition-opacity duration-100 ease-out group-data-[checked]:opacity-100 group-data-[checked]:duration-200 group-data-[checked]:ease-in"
              >
                <svg
                  fill="currentColor"
                  viewBox="0 0 12 12"
                  className="size-3 text-green-600"
                >
                  <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                </svg>
              </span>
            </span>
          </Switch>
        </div>
        <InputErrors
          errors={errors}
          name={name}
        />
      </div>
    );
  }),
});
