import type {ModalSpec} from '@/components/modal/ModalSpec';
import {valtioProxy} from '@/utils/valtio';

// define this in a different file so that his is resilient to HMR reloads
const modalSpecsProxy = valtioProxy<ModalSpec[]>([]);

let modalId = 0;

export function getModalId() {
  return modalId;
}

export function incrModalId() {
  return ++modalId;
}

export default modalSpecsProxy;
